$blockOne: "tranche-json";
$blockTwo: "jgz-json";
$blockThree: "ksv-json";

.#{$blockOne},
.#{$blockTwo},
.#{$blockThree} {
  margin-top: 1rem;

  &-content {
    overflow: scroll;
    width: 80%;
    height: 100px;
    padding: 15px;
    background-color: #ffffff;
  }

  &-copy-json {
    margin-top: 0.5rem !important;
  }
}
