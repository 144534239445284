.tranche-json,
.jgz-json,
.ksv-json {
  margin-top: 1rem;
}
.tranche-json-content,
.jgz-json-content,
.ksv-json-content {
  overflow: scroll;
  width: 80%;
  height: 100px;
  padding: 15px;
  background-color: #ffffff;
}
.tranche-json-copy-json,
.jgz-json-copy-json,
.ksv-json-copy-json {
  margin-top: 0.5rem !important;
}